<template>
    <v-col cols="12">
        <v-row justify="center">
            <auto-search/>
            <v-col cols="10" >
                <v-row justify="center" v-if="loading">
                    <v-progress-circular indeterminate size="72" color="primary"></v-progress-circular>
                </v-row>
                <v-row justify="center">
                    <v-col v-for="(product, index) in products" :key="index" xs="6" sm="4" md="3" class="mx-0">
                        <mini-product-card class="mt-8" v-if="isMobile" :product_details="product"> </mini-product-card>
                        <product-card v-else :product_details="product"> </product-card>
<!--                      <new-product-card :product_details="product"> </new-product-card>-->
                    </v-col>
                </v-row>
            </v-col>
        </v-row>
        <v-row justify="center">
        <v-col cols="10" sm="6" md="4">
            <v-row justify="space-around">
                <v-col cols="5">
                    <v-btn @click="backPage" :disabled="skip < 1" block color="primary" rounded><v-icon left>mdi-arrow-left-circle</v-icon> Back</v-btn>
                </v-col>
                <v-col cols="5">
                    <v-btn @click="nextPage" :disabled="!hasNext" block color="primary" rounded>Next <v-icon right>mdi-arrow-right-circle</v-icon></v-btn>
                </v-col>
            </v-row>
        </v-col>
        </v-row>
    </v-col>
</template>
<script>
import Crud from '../../../gql/PrismaCrud.gql'
import ProductCard from '../../../components/Website/Product/ProductCard'
import MiniProductCard from '../../../components/Website/Product/MiniProductCard'
import AutoSearch from '../../../components/Website/AutoSearch'
import NewProductCard from "@/components/Website/Product/NewProductCard.vue";
// import New_product_card from "@/components/Website/Product/NewProductCard.vue";
const Product = new Crud('product')

export default {
    name: 'ProductsSearch',
    components: {
        NewProductCard,
        ProductCard,
        MiniProductCard,
        AutoSearch
    },
    watch: {
        '$route.params.search'(val){
            this.search_prase = val
            this.getProducts()
        }
    },
    computed: {
        cap(){
            return this.search_prase.charAt(0).toUpperCase() + this.search_prase.slice(1)
        }
    },
    data() {
        return {
            search_prase: '',
            isEmpty: false,
            skip: 0,
            itemsReturned: 20,
            hasNext: false,
            loading: false,
            products: []
        }
    },
    methods: {
        nextPage(){
            this.skip++
            this.getProducts()
        },

        backPage(){
            this.skip--
            this.getProducts()
        },
        getProducts(){
            this.loading = true
            Product.find(`{
                id
                name
                img_url
                lazy_url
                status
                weight
                units
                minimum
                usage
                price
                product_type{
                    id
                    name
                    slug
                }
                category{
                    id
                    name
                    slug
                }
                company{
                    id
                    name
                    location
                    phone
                }
                createdAt
        }`, {
            orderBy: "createdAt_DESC",
            first: this.itemsReturned,
            skip: this.skip * this.itemsReturned,
            where: {
                OR: [
                        {name_contains: this.search_prase},
                        {product_type: {name_contains: this.search_prase, tags_every: {name_contains: this.search_prase}}},
                        {category: {name_contains: this.search_prase}},
                        {company:{name_contains:this.search_prase}},
                        {company:{location_contains:this.search_prase}},

                        {name_contains: this.cap},
                        {product_type: {name_contains: this.cap, tags_every: {name_contains: this.cap}}},
                        {category: {name_contains: this.cap}},
                        {company:{name_contains:this.cap}},
                        {company:{location_contains:this.cap}},
                    ],
                status_not: "deleted"
            }
        }).then(res=>{
            this.products = []
            this.products = res.data
            this.hasNext = res.hasNext
        }).catch(err=>{
            this.$error('Error fetching trending products', err.message)
        }).finally(_=>{
            this.loading = false
        })
        }
    },
    mounted() {
        this.search_prase = this.$route.params.search
        this.getProducts()
    },
}
</script>
